var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "对冲",
        visible: _vm.dialogVisible,
        width: "680px",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "320px" },
                  attrs: { label: "金额", prop: "totalAmount" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.totalAmount,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "totalAmount", $$v)
                      },
                      expression: "form.totalAmount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { label: "备注", prop: "remark" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "form-item" }, [
                _c("label", [
                  _c("span", { staticStyle: { color: "#FF7444" } }, [
                    _vm._v("* "),
                  ]),
                  _vm._v("账单信息 "),
                ]),
                _c(
                  "div",
                  { staticClass: "info" },
                  [
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "info-item" }, [
                            _c("span", { staticClass: "info-l" }, [
                              _vm._v("应收单号"),
                            ]),
                            _c("span", { staticClass: "info-r" }, [
                              _vm._v(_vm._s(_vm.editData.receivableNumber)),
                            ]),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "info-item" }, [
                            _c("span", { staticClass: "info-l" }, [
                              _vm._v("费用项"),
                            ]),
                            _c("span", { staticClass: "info-r" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.feeOptions.find(
                                    (a) => a.value === +_vm.editData.feeNameId
                                  )?.label
                                )
                              ),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "info-item" }, [
                            _c("span", { staticClass: "info-l" }, [
                              _vm._v("派送方式"),
                            ]),
                            _c("span", { staticClass: "info-r" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.mblOptions.find(
                                    (a) =>
                                      a.value === +_vm.editData.mblSendWayId
                                  )?.label
                                )
                              ),
                            ]),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "info-item" }, [
                            _c("span", { staticClass: "info-l" }, [
                              _vm._v("状态"),
                            ]),
                            _c("span", { staticClass: "info-r" }, [
                              _vm._v(_vm._s(_vm.editData.statusName)),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "info-item" }, [
                            _c("span", { staticClass: "info-l" }, [
                              _vm._v("单价"),
                            ]),
                            _c("span", { staticClass: "info-r" }, [
                              _vm._v(_vm._s(_vm.editData.unitPrice) + " USD"),
                            ]),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "info-item" }, [
                            _c("span", { staticClass: "info-l" }, [
                              _vm._v("数量"),
                            ]),
                            _c("span", { staticClass: "info-r" }, [
                              _vm._v(_vm._s(_vm.editData.qty)),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "info-item" }, [
                            _c("span", { staticClass: "info-l" }, [
                              _vm._v("金额"),
                            ]),
                            _c("span", { staticClass: "info-r" }, [
                              _vm._v(_vm._s(_vm.editData.totalAmount) + " USD"),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "info-item" }, [
                            _c("span", { staticClass: "info-l" }, [
                              _vm._v("备注"),
                            ]),
                            _c("span", { staticClass: "info-r" }, [
                              _vm._v(_vm._s(_vm.editData.remark)),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "info-item" }, [
                            _c("span", { staticClass: "info-l" }, [
                              _vm._v("应付单价"),
                            ]),
                            _c("span", { staticClass: "info-r" }, [
                              _vm._v(
                                _vm._s(_vm.editData.payableUnitPrice) + " USD"
                              ),
                            ]),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "info-item" }, [
                            _c("span", { staticClass: "info-l" }, [
                              _vm._v("应付数量"),
                            ]),
                            _c("span", { staticClass: "info-r" }, [
                              _vm._v(_vm._s(_vm.editData.payableQty)),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "info-item" }, [
                            _c("span", { staticClass: "info-l" }, [
                              _vm._v("应付费用"),
                            ]),
                            _c("span", { staticClass: "info-r" }, [
                              _vm._v(
                                _vm._s(_vm.editData.payableTotalAmount) + " USD"
                              ),
                            ]),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "info-item" }, [
                            _c("span", { staticClass: "info-l" }, [
                              _vm._v("应付账单号"),
                            ]),
                            _c("span", { staticClass: "info-r" }, [
                              _vm._v(_vm._s(_vm.editData.payableInvoiceNumber)),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "info-item" }, [
                            _c("span", { staticClass: "info-l" }, [
                              _vm._v("应付备注"),
                            ]),
                            _c("span", { staticClass: "info-r" }, [
                              _vm._v(_vm._s(_vm.editData.payableRemark)),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取消")]
          ),
          _c(
            "div",
            {
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确定")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }