var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-contanier" },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          { staticClass: "tab" },
          _vm._l(_vm.tabList, function (item, $index) {
            return _c(
              "span",
              {
                directives: [
                  {
                    name: "permissson",
                    rawName: "v-permissson",
                    value: `${item.permissson}`,
                    expression: "`${item.permissson}`",
                  },
                ],
                key: item.permissson,
                class: ["tabItem", _vm.active === $index ? "active" : ""],
                on: {
                  click: function ($event) {
                    return _vm.changeType($index)
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(item.name) +
                    " (" +
                    _vm._s(
                      item.type === 10000
                        ? _vm.tabTotal
                        : _vm.tabNumer.find((a) => a.type === item.type)?.qty ||
                            0
                    ) +
                    ") "
                ),
              ]
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "opt" },
          [
            _vm.filterList.invoiceDate && _vm.isActive
              ? _c("div", { staticClass: "checkDate flex_c_c" }, [
                  _vm._v(
                    " 查询时间：" +
                      _vm._s(
                        _vm.filterList.invoiceDate[0] +
                          "~" +
                          _vm.filterList.invoiceDate[1]
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm._l(_vm.deriveListA, function (item, $index) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active === $index,
                      expression: "active === $index",
                    },
                    {
                      name: "permissson",
                      rawName: "v-permissson",
                      value: `${item}`,
                      expression: "`${item}`",
                    },
                  ],
                  key: item,
                  staticClass: "derive_a flex_c_c",
                  on: { click: _vm.derive },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/bill/import.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" 导出 "),
                ]
              )
            }),
            _c(
              "BaseFilter",
              {
                attrs: { isActive: _vm.isActive, width: 486 },
                on: { sendFilter: _vm.sendFilter, reset: _vm.reset },
              },
              [
                _c(
                  "div",
                  { staticClass: "filter" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: { model: _vm.filterList, "label-width": "90px" },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "账单日期" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "320px" },
                              attrs: {
                                type: "daterange",
                                "range-separator": "至",
                                align: "center",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                "value-format": "yyyy-MM-dd",
                                "unlink-panels": "",
                                "picker-options": _vm.pickerOptions,
                              },
                              model: {
                                value: _vm.filterList.invoiceDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "invoiceDate", $$v)
                                },
                                expression: "filterList.invoiceDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "客户" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "320px" },
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择",
                                },
                                model: {
                                  value: _vm.filterList.companyId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filterList, "companyId", $$v)
                                  },
                                  expression: "filterList.companyId",
                                },
                              },
                              _vm._l(_vm.companyOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "业务员" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "320px" },
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择",
                                  multiple: "",
                                },
                                model: {
                                  value: _vm.filterList.saleIds,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filterList, "saleIds", $$v)
                                  },
                                  expression: "filterList.saleIds",
                                },
                              },
                              _vm._l(_vm.userOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "提单号/柜号" } },
                          [
                            _c("base-muti-input", {
                              ref: "baseMutiInput",
                              staticStyle: { width: "320px" },
                              model: {
                                value: _vm.filterList.numbers,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "numbers", $$v)
                                },
                                expression: "filterList.numbers",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          2
        ),
      ]),
      _c(
        "div",
        { staticClass: "data" },
        [
          _vm.height !== 0
            ? _c("BaseTable", {
                key: _vm.active,
                attrs: {
                  height: _vm.height,
                  tableData: _vm.tableData,
                  pageIndex: _vm.pager.pageIndex,
                  pageSize: _vm.pager.pageSize,
                  loading: _vm.loading,
                },
                on: {
                  handlePageChange: _vm.handlePageChange,
                  handleSelectionChange: _vm.handleSelectionChange,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "blNo",
                      fn: function (row) {
                        return [
                          _vm._v(" " + _vm._s(row.scope.blNo) + " "),
                          _c("img", {
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              src: require("@/assets/images/const/viewBill.png"),
                              title: "跟进记录",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.viewlog(row.scope)
                              },
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "opt",
                      fn: function (row) {
                        return [
                          _c("div", { staticClass: "table-opt" }, [
                            _vm.active === 0 && row.scope.status === 0
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "entering_0",
                                        expression: "'entering_0'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.entering(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("录入")]
                                )
                              : _vm._e(),
                            _vm.active === 0 && row.scope.status !== 0
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "detail_0",
                                        expression: "'detail_0'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.detail(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("查看详情")]
                                )
                              : _vm._e(),
                            _vm.active === 1
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "entering",
                                        expression: "'entering'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.entering(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("录入")]
                                )
                              : _vm._e(),
                            _vm.active === 2
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "detail_b",
                                        expression: "'detail_b'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.detail(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("查看详情")]
                                )
                              : _vm._e(),
                            _vm.active === 3
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "detail_c",
                                        expression: "'detail_c'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.detail(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("查看详情")]
                                )
                              : _vm._e(),
                            _vm.active === 4
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "detail_d",
                                        expression: "'detail_d'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.detail(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("查看详情")]
                                )
                              : _vm._e(),
                            _vm.active === 5
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "detail_e",
                                        expression: "'detail_e'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.detail(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("查看详情")]
                                )
                              : _vm._e(),
                            _vm.active === 4
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "complete",
                                        expression: "'complete'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.complete(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("完成")]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "custom-total",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "custom-total2" }, [
                            _c("div", { staticStyle: { display: "flex" } }, [
                              _c("div", { staticClass: "select" }, [
                                _vm._v(
                                  "已选 " +
                                    _vm._s(_vm.selectionItem.length) +
                                    " 项"
                                ),
                              ]),
                              _c("div", {
                                staticClass: "total",
                                domProps: { innerHTML: _vm._s(_vm.totalHtml) },
                              }),
                            ]),
                            _c("div", { staticClass: "total" }, [
                              _c("div", [
                                _vm._v(" 应收合计："),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.receivableTotalAmount) + " USD"
                                  ),
                                ]),
                              ]),
                              _c("div", [
                                _vm._v(" 应付合计："),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.payableTotalAmount) + " USD"
                                  ),
                                ]),
                              ]),
                              _c("div", [
                                _vm._v(" 利润合计："),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.profitTotalAmount) + " USD"
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  937205715
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("ReceivablesDetailDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible1,
          rowData: _vm.rowData,
          status: _vm.active,
        },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible1 = false
          },
          ok: _vm.ok,
        },
      }),
      _c("follow-up-records-drawer", {
        attrs: {
          drawer: _vm.drawer,
          rowData: {
            ..._vm.rowData,
            id: _vm.rowData.mblId,
            status: 4,
          },
          "bill-type": 1,
        },
        on: {
          close: function ($event) {
            _vm.drawer = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }