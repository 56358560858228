<template>
  <el-dialog
    v-drag
    title="对冲"
    :visible.sync="dialogVisible"
    width="680px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-position="top">
        <el-form-item label="金额" prop="totalAmount" style="width: 320px;">
          <el-input v-model="form.totalAmount"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark" style="width: 100%;">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
        <div class="form-item">
          <label>
            <span style="color: #FF7444">* </span>账单信息
          </label>
          <div class="info">
            <el-row>
              <el-col :span="12">
                <div class="info-item">
                  <span class="info-l">应收单号</span>
                  <span class="info-r">{{ editData.receivableNumber }}</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="info-item">
                  <span class="info-l">费用项</span>
                  <span class="info-r">{{ feeOptions.find((a) => a.value === +editData.feeNameId)?.label }}</span>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="info-item">
                  <span class="info-l">派送方式</span>
                  <span class="info-r">{{ mblOptions.find((a) => a.value === +editData.mblSendWayId)?.label }}</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="info-item">
                  <span class="info-l">状态</span>
                  <span class="info-r">{{ editData.statusName }}</span>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="info-item">
                  <span class="info-l">单价</span>
                  <span class="info-r">{{ editData.unitPrice }} USD</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="info-item">
                  <span class="info-l">数量</span>
                  <span class="info-r">{{ editData.qty }}</span>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="info-item">
                  <span class="info-l">金额</span>
                  <span class="info-r">{{ editData.totalAmount }} USD</span>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="info-item">
                  <span class="info-l">备注</span>
                  <span class="info-r">{{ editData.remark }}</span>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="info-item">
                  <span class="info-l">应付单价</span>
                  <span class="info-r">{{ editData.payableUnitPrice }} USD</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="info-item">
                  <span class="info-l">应付数量</span>
                  <span class="info-r">{{ editData.payableQty }}</span>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="info-item">
                  <span class="info-l">应付费用</span>
                  <span class="info-r">{{ editData.payableTotalAmount }} USD</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="info-item">
                  <span class="info-l">应付账单号</span>
                  <span class="info-r">{{ editData.payableInvoiceNumber }}</span>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="info-item">
                  <span class="info-l">应付备注</span>
                  <span class="info-r">{{ editData.payableRemark }}</span>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取消</div>
      <div class="ok flex_c_c" @click="ok('form')">确定</div>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: () => {}
    },
    feeOptions: {
      type: Array,
      default: () => []
    },
    mblOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: {
        totalAmount: '',
        remark: ''
      },
      rules: {
        totalAmount: [{ required: true, message: '请输入到账金额', trigger: 'blur' }],
        remark: [{ required: true, message: '请输入备注内容', trigger: 'blur' }]
      }
    }
  },
  watch: {
    dialogVisible(v) {
      v && (this.form.totalAmount = -this.editData.totalAmount)
    }
  },
  methods: {
    cancel() {
      this.$refs['form'].resetFields()
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const { feeNameId, mblSendWayId, qty, unitPrice } = this.editData
          const obj = {
            feeNameId, mblSendWayId, qty,
            unitPrice: -unitPrice,
            isSendWay: true,
            check: false,
            ...this.form
          }
          this.$emit('ok', obj)
          this.$refs[formName].resetFields()
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 80px);
  margin: 0 auto;
  .form-item {
    .info {
      width: 100%;
      height: 300px;
      background: #F7F7F7;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #EEEEEE;
      box-sizing: border-box;
      margin-top: 5px;
      padding: 20px;
    }
  }
  .el-row + .el-row {
    margin-top: 15px;
  }
  .info-item {
    & span {
      display: inline-block;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
    }
  }
  .info-l {
    color: #999999;
    margin-right: 20px;
  }
  .info-r {
    color: #666666;
  }
  .el-col:nth-child(odd) .info-l {
    width: 56px;
    text-align-last: justify;
    text-align: justify;
  }
  .el-col:nth-child(even) .info-l {
    width: 70px;
    text-align-last: justify;
    text-align: justify;
  }
}

.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  & div {
    width: 120px;
    height: 40px;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    cursor: pointer;
  }
  .cancel {
    background: #eeeeee;
    color: #999999;
    margin-right: 20px;
  }
  .ok {
    background: #2395f3;
    color: #ffffff;
  }
}
</style>
