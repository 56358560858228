var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: _vm.rowData.status === 0 ? "录入" : "详情",
        visible: _vm.dialogVisible,
        width: "1600px",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
        top: "2%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "header" },
          [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: { model: _vm.form, "label-position": "top" },
              },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 4.8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "应付金额" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "290px" },
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.form.payableAmount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "payableAmount", $$v)
                                },
                                expression: "form.payableAmount",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 4.8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "币制" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "290px" },
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.form.currency,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "currency", $$v)
                                },
                                expression: "form.currency",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 4.8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "客户" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "290px" },
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.form.companyName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "companyName", $$v)
                                },
                                expression: "form.companyName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 4.8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "柜号" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "290px" },
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.form.containerNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "containerNumber", $$v)
                                },
                                expression: "form.containerNumber",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 4.8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "提单号" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "290px" },
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.form.blNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "blNo", $$v)
                                },
                                expression: "form.blNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 4.8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "收件人所属" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "290px" },
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.isBelong,
                                callback: function ($$v) {
                                  _vm.isBelong = $$v
                                },
                                expression: "isBelong",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 4.8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "柜型" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "290px" },
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.form.containertype,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "containertype", $$v)
                                },
                                expression: "form.containertype",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 4.8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "派送类型" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "290px" },
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.form.pickUpTypeName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "pickUpTypeName", $$v)
                                },
                                expression: "form.pickUpTypeName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 4.8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "目的港" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "290px" },
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.form.destinationHarborName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "destinationHarborName",
                                    $$v
                                  )
                                },
                                expression: "form.destinationHarborName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 4.8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "条目" } },
                          [
                            _c("el-input-number", {
                              staticStyle: { width: "215px" },
                              attrs: { min: 0, max: 10 },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.newAdd.apply(null, arguments)
                                },
                              },
                              model: {
                                value: _vm.form.entry,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "entry", $$v)
                                },
                                expression: "form.entry",
                              },
                            }),
                            _c(
                              "el-button",
                              {
                                staticStyle: { width: "75px" },
                                on: { click: _vm.newAdd },
                              },
                              [_vm._v("增加")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "data" },
          [
            _c(
              "el-table",
              {
                ref: "multipleTable",
                attrs: {
                  data: _vm.tableData.columns,
                  border: "",
                  width: "100%",
                  height: "440px",
                  "header-cell-style": _vm.headerStyle,
                  "cell-style": _vm.cellStyle,
                },
                on: { "selection-change": _vm.selectionChange },
              },
              [
                _vm.status === 2
                  ? _c("el-table-column", {
                      attrs: {
                        align: "center",
                        fixed: "left",
                        type: "selection",
                        selectable: _vm.selectable,
                        resizable: "",
                      },
                    })
                  : _vm._e(),
                _vm._l(_vm.tableData.fileds, function (item, index) {
                  return _c(
                    "el-table-column",
                    {
                      key: index,
                      attrs: {
                        align: "center",
                        "header-align": "center",
                        "show-overflow-tooltip": true,
                        prop: item.filed,
                        label: item.label,
                        width: item.width,
                        formatter: item.formatter,
                        resizable: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.status !== 1 &&
                                item.filed !== "feeNameId" &&
                                item.filed !== "mblSendWayId"
                                  ? _c("el-input", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        id: `${item.filed + scope.$index}`,
                                        placeholder: "请输入",
                                        disabled: item.disabled,
                                        oninput: item.keyup,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.onChang(scope.row)
                                        },
                                        focus: function ($event) {
                                          return _vm.inputFocus(
                                            item.filed,
                                            scope.$index
                                          )
                                        },
                                      },
                                      nativeOn: {
                                        keydown: [
                                          function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.handleTab(
                                              item.filed,
                                              scope.$index + 1
                                            )
                                          },
                                          function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "tab",
                                                9,
                                                $event.key,
                                                "Tab"
                                              )
                                            )
                                              return null
                                            return _vm.handleTab(
                                              item.filed,
                                              scope.$index + 1
                                            )
                                          },
                                          function ($event) {
                                            return _vm.arrow(
                                              $event,
                                              item.filed,
                                              scope.$index
                                            )
                                          },
                                        ],
                                      },
                                      model: {
                                        value: scope.row[item.filed],
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, item.filed, $$v)
                                        },
                                        expression: "scope.row[item.filed]",
                                      },
                                    })
                                  : scope.row.status !== 1 &&
                                    (item.filed === "feeNameId" ||
                                      item.filed === "mblSendWayId")
                                  ? _c(
                                      "el-select",
                                      {
                                        ref: `${item.filed + scope.$index}`,
                                        refInFor: true,
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          id: `${item.filed + scope.$index}`,
                                          filterable: "",
                                          placeholder: "请选择",
                                          "filter-method": (e) =>
                                            _vm.filterMethod(
                                              e,
                                              item.filed === "feeNameId"
                                                ? _vm.feeOptionsCopy
                                                : _vm.mblOptionsCopy,
                                              scope.row,
                                              item.filed
                                            ),
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.onBlur(
                                              item.filed,
                                              scope.row,
                                              scope.row[item.filed]
                                            )
                                          },
                                          focus: function ($event) {
                                            return _vm.onFocus(
                                              item.filed,
                                              scope.row,
                                              scope.row[item.filed]
                                            )
                                          },
                                          change: function ($event) {
                                            return _vm.onChange(
                                              item.filed,
                                              scope.row,
                                              scope.row[item.filed]
                                            )
                                          },
                                          "visible-change": (e) =>
                                            _vm.visibleChange(e, item.filed),
                                        },
                                        nativeOn: {
                                          keydown: function ($event) {
                                            return _vm.arrow(
                                              $event,
                                              item.filed,
                                              scope.$index
                                            )
                                          },
                                        },
                                        model: {
                                          value: scope.row[item.filed],
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, item.filed, $$v)
                                          },
                                          expression: "scope.row[item.filed]",
                                        },
                                      },
                                      [
                                        scope.row.isSendWay &&
                                        item.filed === "mblSendWayId"
                                          ? _vm._l(
                                              _vm.mblOptions,
                                              function (a) {
                                                return _c("el-option", {
                                                  key: a.value,
                                                  attrs: {
                                                    label: a.label,
                                                    value: a.value,
                                                  },
                                                })
                                              }
                                            )
                                          : item.filed === "feeNameId"
                                          ? _vm._l(
                                              _vm.feeOptions,
                                              function (a) {
                                                return _c("el-option", {
                                                  key: a.value,
                                                  attrs: {
                                                    label: a.label,
                                                    value: a.value,
                                                  },
                                                })
                                              }
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _c(
                                      "p",
                                      [
                                        item.filed === "unitPrice" ||
                                        item.filed === "totalAmount" ||
                                        item.filed === "payableUnitPrice" ||
                                        item.filed === "payableTotalAmount"
                                          ? [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    scope.row[item.filed],
                                                    item.filed,
                                                    scope.row
                                                  )
                                                )
                                              ),
                                            ]
                                          : item.filed === "feeNameId"
                                          ? [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("feeName")(
                                                    scope.row[item.filed],
                                                    _vm.that
                                                  )
                                                )
                                              ),
                                            ]
                                          : item.filed === "mblSendWayId"
                                          ? [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("sendWay")(
                                                    scope.row[item.filed],
                                                    _vm.that
                                                  )
                                                )
                                              ),
                                            ]
                                          : [
                                              _vm._v(
                                                _vm._s(scope.row[item.filed])
                                              ),
                                            ],
                                      ],
                                      2
                                    ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _vm.ifFiled(item.filed)
                          ? _c("div", [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("* "),
                              ]),
                              _vm._v(_vm._s(item.label) + " "),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    2
                  )
                }),
                _c("el-table-column", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    align: "center",
                    fixed: "right",
                    label: "操作",
                    width: "94",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "tab-bt" },
                            [
                              scope.row.status === 1
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.hedgingRow(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v(" 对冲 ")]
                                  )
                                : _vm._e(),
                              scope.row.status !== 1
                                ? [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/const/delete.png"),
                                        title: "删除",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteRow(scope.$index)
                                        },
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              2
            ),
            _c("div", { staticClass: "custom-total flex_c_c" }, [
              _c("div", [
                _vm._v(" 总条数："),
                _c("span", [_vm._v(_vm._s(_vm.tableData.columns.length))]),
              ]),
              _c("div", [
                _vm._v(" 应收合计："),
                _c("span", [
                  _vm._v(_vm._s(_vm.receivableTotalAmount) + " USD"),
                ]),
              ]),
              _c("div", [
                _vm._v(" 应付合计："),
                _c("span", [_vm._v(_vm._s(_vm.payableTotalAmount) + " USD")]),
              ]),
              _c("div", [
                _vm._v(" 利润："),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.receivableTotalAmount - _vm.payableTotalAmount) +
                      " USD"
                  ),
                ]),
              ]),
            ]),
            _vm.status === 2
              ? _c("div", { staticClass: "data-footer" }, [
                  _vm._v(" 已选择 "),
                  _c("span", [_vm._v(_vm._s(_vm.selectionItem.length))]),
                  _vm._v(" 项 "),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取消")]
          ),
          _c("div", { staticClass: "ok flex_c_c", on: { click: _vm.ok } }, [
            _vm._v("提交"),
          ]),
          _c(
            "span",
            {
              staticClass: "optDocument flex_c_c",
              on: {
                click: function ($event) {
                  _vm.dialogVisible1 = true
                },
              },
            },
            [_c("i", { staticClass: "el-icon-question" }), _vm._v("操作说明 ")]
          ),
        ]
      ),
      _c("OptDocumentDialog", {
        attrs: { dialogVisible: _vm.dialogVisible1, type: "Receivables" },
        on: {
          ok: function ($event) {
            _vm.dialogVisible1 = false
          },
        },
      }),
      _c("HedgingDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible2,
          editData: _vm.editData,
          feeOptions: _vm.feeOptionsCopy,
          mblOptions: _vm.mblOptionsCopy,
        },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible2 = false
          },
          ok: _vm.ok1,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }