<template>
  <el-dialog
    v-drag
    :title="rowData.status === 0 ? '录入' : '详情'"
    :visible.sync="dialogVisible"
    width="1600px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
    top="2%"
  >
    <div class="content">
      <div class="header">
        <el-form ref="form" :model="form" label-position="top">
          <el-row :gutter="20">
            <el-col :span="4.8">
              <el-form-item label="应付金额">
                <el-input
                  v-model="form.payableAmount"
                  style="width: 290px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4.8">
              <el-form-item label="币制">
                <el-input
                  v-model="form.currency"
                  style="width: 290px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4.8">
              <el-form-item label="客户">
                <el-input
                  v-model="form.companyName"
                  style="width: 290px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4.8">
              <el-form-item label="柜号">
                <el-input
                  v-model="form.containerNumber"
                  style="width: 290px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4.8">
              <el-form-item label="提单号">
                <el-input
                  v-model="form.blNo"
                  style="width: 290px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="4.8">
              <el-form-item label="收件人所属">
                <el-input
                  v-model="isBelong"
                  style="width: 290px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4.8">
              <el-form-item label="柜型">
                <el-input
                  v-model="form.containertype"
                  style="width: 290px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4.8">
              <el-form-item label="派送类型">
                <el-input
                  v-model="form.pickUpTypeName"
                  style="width: 290px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4.8">
              <el-form-item label="目的港">
                <el-input
                  v-model="form.destinationHarborName"
                  style="width: 290px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4.8">
              <el-form-item label="条目">
                <el-input-number
                  v-model="form.entry"
                  :min="0"
                  :max="10"
                  style="width: 215px"
                  @keyup.enter.native="newAdd"
                ></el-input-number>
                <el-button
                  style="width: 75px"
                  @click="newAdd"
                >增加</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="data">
        <el-table
          ref="multipleTable"
          :data="tableData.columns"
          border
          width="100%"
          height="440px"
          :header-cell-style="headerStyle"
          :cell-style="cellStyle"
          @selection-change="selectionChange"
        >
          <el-table-column
            v-if="status === 2"
            align="center"
            fixed="left"
            type="selection"
            :selectable="selectable"
            resizable
          ></el-table-column>
          <el-table-column
            v-for="(item, index) in tableData.fileds"
            :key="index"
            align="center"
            header-align="center"
            :show-overflow-tooltip="true"
            :prop="item.filed"
            :label="item.label"
            :width="item.width"
            :formatter="item.formatter"
            resizable
          >
            <template slot="header">
              <div v-if="ifFiled(item.filed)">
                <span style="color: red;">* </span>{{ item.label }}
              </div>
            </template>
            <template slot-scope="scope">
              <el-input
                v-if="
                  scope.row.status !== 1 &&
                    item.filed !== 'feeNameId' &&
                    item.filed !== 'mblSendWayId'
                "
                :id="`${item.filed + scope.$index}`"
                v-model="scope.row[item.filed]"
                placeholder="请输入"
                style="width: 100%"
                :disabled="item.disabled"
                :oninput="item.keyup"
                @change="onChang(scope.row)"
                @keydown.enter.native="handleTab(item.filed, scope.$index + 1)"
                @keydown.tab.native="handleTab(item.filed, scope.$index + 1)"
                @keydown.native="arrow($event, item.filed, scope.$index)"
                @focus="inputFocus(item.filed, scope.$index)"
              ></el-input>
              <el-select
                v-else-if="
                  scope.row.status !== 1 && (item.filed === 'feeNameId' || item.filed === 'mblSendWayId')
                "
                :id="`${item.filed + scope.$index}`"
                :ref="`${item.filed + scope.$index}`"
                v-model="scope.row[item.filed]"
                style="width: 100%"
                filterable
                placeholder="请选择"
                :filter-method="
                  (e) =>
                    filterMethod(
                      e,
                      item.filed === 'feeNameId' ? feeOptionsCopy : mblOptionsCopy,
                      scope.row,
                      item.filed
                    )
                "
                @blur="onBlur(item.filed, scope.row, scope.row[item.filed])"
                @focus="onFocus(item.filed, scope.row, scope.row[item.filed])"
                @change="onChange(item.filed, scope.row, scope.row[item.filed])"
                @keydown.native="arrow($event, item.filed, scope.$index)"
                @visible-change="(e) => visibleChange(e, item.filed)"
              >
                <template
                  v-if="scope.row.isSendWay && item.filed === 'mblSendWayId'"
                >
                  <el-option
                    v-for="a in mblOptions"
                    :key="a.value"
                    :label="a.label"
                    :value="a.value"
                  ></el-option>
                </template>
                <template v-else-if="item.filed === 'feeNameId'">
                  <el-option
                    v-for="a in feeOptions"
                    :key="a.value"
                    :label="a.label"
                    :value="a.value"
                  ></el-option>
                </template>
              </el-select>
              <p v-else>
                <template
                  v-if="
                    item.filed === 'unitPrice' ||
                      item.filed === 'totalAmount' ||
                      item.filed === 'payableUnitPrice' ||
                      item.filed === 'payableTotalAmount'
                  "
                >{{
                  scope.row[item.filed] | currency(item.filed, scope.row)
                }}</template
                >
                <template v-else-if="item.filed === 'feeNameId'">{{
                  scope.row[item.filed] | feeName(that)
                }}</template>
                <template v-else-if="item.filed === 'mblSendWayId'">{{
                  scope.row[item.filed] | sendWay(that)
                }}</template>
                <template v-else>{{ scope.row[item.filed] }}</template>
              </p>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            fixed="right"
            label="操作"
            width="94"
            style="width: 100%"
          >
            <template slot-scope="scope">
              <div class="tab-bt">
                <el-button
                  v-if="scope.row.status === 1"
                  type="text"
                  size="small"
                  @click="hedgingRow(scope.row)"
                >
                  对冲
                </el-button>
                <template v-if="scope.row.status !== 1">
                  <img
                    src="@/assets/images/const/delete.png"
                    title="删除"
                    @click="deleteRow(scope.$index)"
                  />
                </template>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="custom-total flex_c_c">
          <div>
            总条数：<span>{{ tableData.columns.length }}</span>
          </div>
          <div>
            应收合计：<span>{{ receivableTotalAmount }} USD</span>
          </div>
          <div>
            应付合计：<span>{{ payableTotalAmount }} USD</span>
          </div>
          <div>
            利润：<span>{{ receivableTotalAmount - payableTotalAmount }} USD</span>
          </div>
        </div>
        <div v-if="status === 2" class="data-footer">
          已选择 <span>{{ selectionItem.length }}</span> 项
        </div>
      </div>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取消</div>
      <div class="ok flex_c_c" @click="ok">提交</div>
      <span class="optDocument flex_c_c" @click="dialogVisible1 = true">
        <i class="el-icon-question"></i>操作说明
      </span>
    </span>
    <OptDocumentDialog
      :dialogVisible="dialogVisible1"
      type="Receivables"
      @ok="dialogVisible1 = false"
    ></OptDocumentDialog>
    <HedgingDialog
      :dialogVisible="dialogVisible2"
      :editData="editData"
      :feeOptions="feeOptionsCopy"
      :mblOptions="mblOptionsCopy"
      @cancel="dialogVisible2 = false"
      @ok="ok1"
    ></HedgingDialog>
  </el-dialog>
</template>
<script>
import OptDocumentDialog from './OptDocumentDialog'
import HedgingDialog from './HedgingDialog'
import {
  getReceivableDetail,
  updateReceivableDetail,
  reviewedReceivableDetails,
  GetFeeNameList
} from '@/api/fee'
import { getMBLSendWay } from '@/api/bill'
import { debounce } from '@/utils/common'
import { mapGetters } from 'vuex'
export default {
  components: {
    OptDocumentDialog,
    HedgingDialog
  },
  filters: {
    // eslint-disable-next-line consistent-this
    sendWay(id, that) {
      if (id && that.mblOptions.length > 0) {
        return that.mblOptionsCopy.find((a) => a.value === +id)?.label
      } else {
        return ''
      }
    },
    // eslint-disable-next-line consistent-this
    feeName(id, that) {
      if (id && that.feeOptionsCopy.length > 0) {
        return that.feeOptionsCopy.find((a) => a.value === +id)?.label
      } else {
        return ''
      }
    },
    currency(val, filed, row) {
      if (filed === 'unitPrice' || filed === 'totalAmount') {
        return `${val} ${row.currency}`
      } else if (
        filed === 'payableUnitPrice' ||
        filed === 'payableTotalAmount'
      ) {
        return row.payableCurrency ? `${val} ${row.payableCurrency}` : val
      }
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => {}
    },
    status: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      that: this,
      tableData: {
        fileds: [
          {
            filed: 'receivableNumber',
            label: '应收单号',
            width: '150',
            disabled: true
          },
          {
            filed: 'feeNameId',
            label: '费用项',
            width: '140'
          },
          {
            filed: 'mblSendWayId',
            label: '派送方式',
            width: '140'
          },
          {
            filed: 'statusName',
            label: '状态',
            width: '80',
            disabled: true
          },
          {
            filed: 'qty',
            label: '数量',
            width: '80',
            keyup: `value=value.replace(/[^0-9.]/g, '')`
          },
          {
            filed: 'unitPrice',
            label: '单价',
            width: '100',
            keyup: `value=value.replace(/[^-0-9.]/g, '')`
          },
          {
            filed: 'totalAmount',
            label: '金额',
            width: '100',
            disabled: true
          },
          {
            filed: 'remark',
            label: '备注',
            width: '200'
          },
          {
            filed: 'payableQty',
            label: '应付数量',
            width: '80',
            disabled: true
          },
          {
            filed: 'payableUnitPrice',
            label: '应付单价',
            width: '100',
            disabled: true
          },
          {
            filed: 'payableTotalAmount',
            label: '应付费用',
            width: '100',
            disabled: true
          },
          {
            filed: 'payableInvoiceNumber',
            label: '应付账单号',
            width: '140',
            disabled: true
          },
          {
            filed: 'payableRemark',
            label: '应付备注',
            width: '200',
            disabled: true
          }
        ],
        columns: []
      },
      form: {},
      // receivableTotalAmount: 0,
      payableTotalAmount: 0,
      mblOptions: [],
      mblOptionsCopy: [],
      feeOptions: [],
      feeOptionsCopy: [],
      addObj: {
        feeNameId: '',
        mblSendWayId: '',
        qty: '1',
        unitPrice: '',
        totalAmount: '',
        remark: '',
        isSendWay: false,
        check: false
      },
      selectionItem: [],
      dialogVisible1: false,
      dialogVisible2: false,
      newAddFlag: false,
      editData: {}
    }
  },
  computed: {
    ...mapGetters([
      'addressBookOptions'
    ]),
    receivableTotalAmount() {
      return this.tableData.columns.reduce((prev, next) => {
        return (prev += +next.qty * (next.unitPrice !== '-' && +next.unitPrice))
      }, 0)
    },
    isBelong() {
      const item = this.addressBookOptions.find(a => a.companyName === this.rowData.consigneeName)
      return item?.supplierId ? '自有' : '客户'
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.tableData.columns = []
        this.getMBLSendWay()
        this.GetFeeNameList()
        this.rowData.id &&
          (this.form = {
            ...this.rowData,
            entry: 0
          })
        this.getList()
      }
    }
  },
  async mounted() {
    if (!this.addressBookOptions || this.addressBookOptions.length === 0) {
      this.$store.dispatch('getAddressBookOptions', { type: -1 })
    }
  },
  methods: {
    ifFiled(v) {
      if (v === 'feeNameId' || v === 'qty' || v === 'unitPrice' || v === 'totalAmount') {
        return true
      } else {
        return false
      }
    },
    async GetFeeNameList() {
      try {
        const res = await GetFeeNameList({ feeTypeCode: 'MBLF' })
        if (res.success) {
          const feeOptions = res.data.map((item) => {
            return {
              label: `${item.code} - ${item.name}`,
              value: item.id,
              isSendWay: item.isSendWay
            }
          })
          this.feeOptions = feeOptions
          this.feeOptionsCopy = feeOptions
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 派送方式
    async getMBLSendWay() {
      try {
        const res = await getMBLSendWay({ mblId: this.rowData.mblId })
        if (res.success) {
          const mblOptions = res.data.map((item) => {
            return {
              label: item.typeName,
              value: item.id
            }
          })
          this.mblOptions = mblOptions
          this.mblOptionsCopy = mblOptions
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getList() {
      try {
        const result = await getReceivableDetail({ id: this.rowData.id })
        if (result.success) {
          this.tableData.columns = result.data.data.map((a) => {
            const isSendWay = this.feeOptionsCopy.find(
              (b) => b.value === +a.feeNameId
            )?.isSendWay
            return {
              ...a,
              feeNameId: +a.feeNameId,
              mblSendWayId: a.mblSendWayId ? +a.mblSendWayId : null,
              check: !a.status,
              isSendWay: isSendWay || false
            }
          })
          this.status !== 2 && this.status !== 3 &&
            this.tableData.columns.push({
              feeNameId: '',
              mblSendWayId: '',
              qty: '1',
              unitPrice: '',
              totalAmount: '',
              remark: '',
              isSendWay: false,
              check: false
            })
          // this.receivableTotalAmount = result.data.receivableTotalAmount
          this.payableTotalAmount = result.data.payableTotalAmount
          if (this.status === 2) {
            this.$nextTick(() => {
              this.tableData.columns
                .filter((a) => a.status === 0)
                .forEach((item) => {
                  this.$refs.multipleTable.toggleRowSelection(item, true)
                })
            })
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    headerStyle({ columnIndex }) {
      if (this.status !== 2) {
        if ((columnIndex !== 13 && columnIndex > 7) || columnIndex === 6) {
          return {
            background: '#D6F3E6',
            color: '#333333',
            fontWeight: '400'
          }
        } else {
          return {
            background: '#F7F7F7',
            fontWeight: '400',
            color: '#333333'
          }
        }
      } else {
        if ((columnIndex !== 14 && columnIndex > 8) || columnIndex === 7) {
          return {
            background: '#D6F3E6',
            color: '#333333',
            fontWeight: '400'
          }
        } else {
          return {
            background: '#F7F7F7',
            fontWeight: '400',
            color: '#333333'
          }
        }
      }
    },
    cellStyle({ columnIndex }) {
      if (this.status !== 2) {
        if ((columnIndex !== 13 && columnIndex > 7) || columnIndex === 6) {
          return {
            background: '#F4FCF9'
          }
        }
      } else {
        if ((columnIndex !== 14 && columnIndex > 8) || columnIndex === 7) {
          return {
            background: '#F4FCF9'
          }
        }
      }
    },
    // 自动计算金额
    onChang(row) {
      const { qty, unitPrice } = row
      qty &&
        unitPrice &&
        (row.totalAmount =
          +qty.toString().replace(/[^0-9.]/g, '') *
          +unitPrice.toString().replace(/[^-0-9.]/g, ''))
      if (!qty || !unitPrice) {
        row.totalAmount = ''
      }
    },
    selectable(row) {
      return row?.check
    },
    selectionChange(v) {
      this.selectionItem = v
    },
    // 换行
    handleTab(filed, index) {
      const length = this.tableData.columns.length
      this.newAddFlag = false
      if (filed === 'remark' && index === length) {
        this.tableData.columns.push({
          feeNameId: '',
          mblSendWayId: '',
          qty: '1',
          unitPrice: '',
          totalAmount: '',
          remark: '',
          check: false
        })
      }
    },
    // 自定义下拉框搜索
    filterMethod: debounce(async function(e, array, item, filed) {
      if (e) {
        const value = e.toLowerCase()
        const arr = [...array].filter((a) =>
          a.label.toLowerCase().includes(value)
        )
        await filed === 'feeNameId' && (this.feeOptions = arr)
        await filed === 'mblSendWayId' && (this.mblOptions = arr)
        arr[0]?.value &&
          filed === 'feeNameId' &&
          (item.feeNameId = arr[0]?.value)
        arr[0]?.value &&
          filed === 'mblSendWayId' &&
          (item.mblSendWayId = arr[0]?.value)
      } else {
        this.feeOptions = this.feeOptionsCopy
        this.mblOptions = this.mblOptionsCopy
      }
    }, 400),
    // 获取焦点默认选中
    onFocus(filed, item) {
      if (filed === 'feeNameId') {
        if (item.feeNameId === 1055) {
          const weightKgs = (this.rowData.weightKgs * 2.2046).toFixed(2)
          const weightCbm = (this.rowData.weightCbm / 167 * 2.2046).toFixed(2)
          weightKgs > weightCbm && (item.qty = Math.ceil(weightKgs))
          weightKgs < weightCbm && (item.qty = Math.ceil(weightCbm))
          weightKgs === weightCbm && (item.qty = Math.ceil(weightKgs))
        }
        if (item.feeNameId === 1056) {
          const weightCbm = (this.rowData.weightCbm / 1.8)
          item.qty = Math.ceil(weightCbm)
        }
        item.mblSendWayId = ''
        !item.feeNameId && (item.feeNameId = this.feeOptions[0]?.value)
        !item.isSendWay && (item.isSendWay = this.feeOptions[0]?.isSendWay)
      } else {
        item.isSendWay && (item.mblSendWayId = this.mblOptions[0]?.value)
      }
    },
    onBlur(filed, item, v) {
      if (filed === 'feeNameId') {
        item.isSendWay = this.feeOptionsCopy.find(
          (a) => a.value === v
        )?.isSendWay
      }
    },
    onChange(filed, item, v) {
      if (filed === 'feeNameId') {
        this.feeOptions = this.feeOptionsCopy
        item.isSendWay = this.feeOptionsCopy.find(
          (a) => a.value === v
        )?.isSendWay
        if (item.feeNameId === 1055) {
          const weightKgs = (this.rowData.weightKgs * 2.2046).toFixed(2)
          const weightCbm = (this.rowData.weightCbm / 167 * 2.2046).toFixed(2)
          weightKgs > weightCbm && (item.qty = Math.ceil(weightKgs))
          weightKgs < weightCbm && (item.qty = Math.ceil(weightCbm))
          weightKgs === weightCbm && (item.qty = Math.ceil(weightKgs))
        }
        if (item.feeNameId === 1056) {
          const weightCbm = (this.rowData.weightCbm / 1.8)
          item.qty = Math.ceil(weightCbm)
        }
      } else {
        this.mblOptions = this.mblOptionsCopy
      }
    },
    visibleChange(e, filed) {
      if (e) {
        if (filed === 'feeNameId') {
          this.feeOptions = this.feeOptionsCopy
        } else {
          this.mblOptions = this.mblOptionsCopy
        }
      }
    },
    inputFocus(filed, index) {
      if (filed !== 'remark') {
        this.$refs[filed + index][0].select()
      }
    },
    // 键盘箭头事件
    arrow(e, filed, index) {
      const keyCode = e.keyCode
      // 当前dom元素id
      const id = filed + index
      // 光标长度
      const cursurPosition = document.getElementById(id).selectionStart
      // 文本长度
      const textLenght = document.getElementById(id).value.length
      // 数据数组
      const tableData = this.tableData.columns
      switch (keyCode) {
        case 37:
          if (cursurPosition === 0) {
            if (filed === 'qty') {
              if (
                index !== tableData.length &&
                tableData[index].isSendWay &&
                !tableData[index].mblSendWayId
              ) {
                tableData[index].mblSendWayId = this.mblOptionsCopy[0].value
              } else if (
                index === tableData.length &&
                this.contrastObj.isSendWay &&
                !this.contrastObj.mblSendWayId
              ) {
                this.contrastObj.mblSendWayId = this.mblOptionsCopy[0].value
              }
            }
            filed === 'qty' &&
              document.getElementById(`mblSendWayId${index}`).focus()
            filed === 'unitPrice' &&
              document.getElementById(`qty${index}`).focus()
            filed === 'remark' &&
              document.getElementById(`unitPrice${index}`).focus()
          }
          break
        case 39:
          if (cursurPosition === textLenght) {
            filed === 'qty' &&
              document.getElementById(`unitPrice${index}`).focus()
            filed === 'unitPrice' &&
              document.getElementById(`remark${index}`).focus()
          }
          break
        case 38:
          if (index) {
            // 筛选掉不可输入的行数据，同时从后面开始获取比当前下标小的
            const isCanInputIndex = tableData.findLastIndex(
              (a, i) => a.status !== 1 && i < index
            )
            filed === 'qty' &&
              document.getElementById(`qty${isCanInputIndex}`).focus()
            filed === 'unitPrice' &&
              document.getElementById(`unitPrice${isCanInputIndex}`).focus()
            filed === 'remark' &&
              document.getElementById(`remark${isCanInputIndex}`).focus()
          }
          break
        case 40:
          if (index + 1 < tableData.length) {
            // 筛选掉不可输入的行数据，同时从前面开始获取比当前下标大的
            const isCanInputIndex = tableData.findIndex(
              (a, i) => a.status !== 1 && i > index
            )
            filed === 'qty' &&
              document.getElementById(`qty${isCanInputIndex}`).focus()
            filed === 'unitPrice' &&
              document.getElementById(`unitPrice${isCanInputIndex}`).focus()
            filed === 'remark' &&
              document.getElementById(`remark${isCanInputIndex}`).focus()
          }
          break
      }
    },
    // 删除
    async deleteRow(index) {
      this.tableData.columns.splice(index, 1)
    },
    cancel() {
      this.tableData.columns = []
      this.$emit('cancel')
    },
    // 提交和审核
    async ok() {
      try {
        const ids = this.selectionItem.map((a) => a.id)
        const isNoId = this.tableData.columns.filter((a) => !a.id)
        const tableData = [...this.tableData.columns]
        let flag1 = false
        let flag2 = false
        tableData.forEach((a, i) => {
          const { feeNameId, qty, unitPrice } = a
          if (
            i + 1 !== tableData.length &&
            (!feeNameId || !qty || +qty === 0 || !unitPrice || +unitPrice === 0)
          ) {
            flag1 = true
          }
          if (i + 1 === tableData.length && !feeNameId && !qty && !unitPrice) {
            flag2 = true
          }
        })
        flag2 && tableData.splice(tableData.length - 1, 1)
        const params = {
          data: tableData,
          invoiceId: this.rowData.id
        }
        if (ids.length > 0 && isNoId.length > 0 && !flag2) {
          if (flag1) {
            this.$message.warning(
              '费用项、数量、价格不能为空，且数量价格不能为0'
            )
            return
          }
          if (!flag2) {
            const { feeNameId, qty, unitPrice } =
              tableData[tableData.length - 1]
            if (
              !feeNameId ||
              !qty ||
              !unitPrice ||
              +qty === 0 ||
              +unitPrice === 0
            ) {
              this.$message.warning(
                '费用项、数量、价格不能为空，且数量价格不能为0'
              )
              return
            }
          }
          Promise.all([
            updateReceivableDetail(params),
            reviewedReceivableDetails(ids)
          ]).then((result) => {
            if (result[0].success && result[1].success) {
              this.$emit('ok')
              this.cancel()
              this.$message.success(result[0].errorMessage)
            } else {
              this.$message.error(
                result[0].success
                  ? result[0].errorMessage
                  : result[1].errorMessage
              )
            }
          })
          return
        } else if (ids.length > 0) {
          // 审核
          if (flag1) {
            this.$message.warning(
              '费用项、数量、价格不能为空，且数量价格不能为0'
            )
            return
          }
          const result = await reviewedReceivableDetails(ids)
          if (result.success) {
            this.cancel()
            this.$emit('ok')
            this.$message.success(result.errorMessage)
          } else {
            this.$message.error(result.errorMessage)
          }
          return
        } else if (ids.length === 0) {
          // 更新
          if (flag1) {
            this.$message.warning(
              '费用项、数量、价格不能为空，且数量价格不能为0'
            )
            return
          }
          if (!flag2) {
            const { feeNameId, qty, unitPrice } =
              tableData[tableData.length - 1]
            if (
              !feeNameId ||
              !qty ||
              !unitPrice ||
              +qty === 0 ||
              +unitPrice === 0
            ) {
              this.$message.warning(
                '费用项、数量、价格不能为空，且数量价格不能为0'
              )
              return
            }
          }
          const result = await updateReceivableDetail(params)
          if (result.success) {
            this.cancel()
            this.$emit('ok')
            this.$message.success(result.errorMessage)
          } else {
            this.$message.error(result.errorMessage)
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    newAdd() {
      if (!this.form.entry) return
      this.newAddFlag = true
      let i = 0
      while (i < this.form.entry) {
        this.tableData.columns.push({
          feeNameId: '',
          mblSendWayId: '',
          qty: '1',
          unitPrice: '',
          totalAmount: '',
          remark: '',
          isSendWay: false,
          check: false
        })
        i++
      }
    },
    hedgingRow(row) {
      this.editData = row
      this.dialogVisible2 = true
    },
    ok1(obj) {
      this.tableData.columns.push(obj)
      this.dialogVisible2 = false
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .el-table--border .el-table__cell:first-child .cell {
  padding: 0;
}
/deep/ .el-table tr td {
  height: 40px;
  padding: 0;
}
/deep/ .el-table tr td .cell {
  padding: 0;
}
.content {
  width: 100%;
  margin: 0 auto;
  .header {
    .el-form {
      display: flex;
      flex-wrap: wrap;
    }
    .el-form-item {
      margin-bottom: 15px;
      & /deep/ .el-form-item__label {
        line-height: normal;
      }
      & /deep/ .el-form-item__content {
        display: flex;
      }
    }
  }
  .data {
    width: 100%;
    border-radius: 4px 4px 4px 4px;
    overflow: hidden;
    .custom-total {
      width: 100%;
      height: 40px;
      background: #f7f7f7;
      border-radius: 0px 0px 4px 4px;
      border: 1px solid #eeeeee;
      box-sizing: border-box;
      span {
        color: #fe453d;
        font-weight: bold;
      }
      div + div {
        margin-left: 20px;
      }
    }
    .data-footer {
      margin-top: 20px;
      margin-left: 10px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      span {
        color: #2395f3;
      }
    }
    :deep(.el-input__inner) {
      border: 0;
    }
  }
  .tab-bt {
    display: flex;
    justify-content: space-around;
    align-items: center;
    img {
      cursor: pointer;
    }
  }
  .add {
    color: #2395f3;
    cursor: pointer;
  }
}
.footers {
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin-right: 16px;
  }
  .optDocument {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #fe453d;
    cursor: pointer;
  }
}
</style>
