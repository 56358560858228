<template>
  <div class="view-contanier">
    <div class="header">
      <div class="tab">
        <span
          v-for="(item, $index) in tabList"
          :key="item.permissson"
          v-permissson="`${item.permissson}`"
          :class="['tabItem', active === $index ? 'active' : '']"
          @click="changeType($index)"
        >
          {{ item.name }}
          ({{ item.type === 10000 ? tabTotal : tabNumer.find(a => a.type === item.type)?.qty || 0 }})
        </span>
      </div>
      <div class="opt">
        <div v-if="filterList.invoiceDate && isActive" class="checkDate flex_c_c">
          查询时间：{{ filterList.invoiceDate[0] + "~" + filterList.invoiceDate[1] }}
        </div>
        <div
          v-for="(item, $index) in deriveListA"
          v-show="active === $index"
          :key="item"
          v-permissson="`${item}`"
          class="derive_a flex_c_c"
          @click="derive"
        >
          <img src="@/assets/images/bill/import.png" alt="" />
          导出
        </div>
        <BaseFilter
          :isActive="isActive"
          :width="486"
          @sendFilter="sendFilter"
          @reset="reset"
        >
          <div class="filter">
            <el-form ref="form" :model="filterList" label-width="90px">
              <el-form-item label="账单日期">
                <el-date-picker
                  v-model="filterList.invoiceDate"
                  style="width: 320px"
                  type="daterange"
                  range-separator="至"
                  align="center"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  unlink-panels
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="客户">
                <el-select
                  v-model="filterList.companyId"
                  style="width: 320px"
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in companyOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="业务员">
                <el-select
                  v-model="filterList.saleIds"
                  style="width: 320px"
                  filterable
                  placeholder="请选择"
                  multiple
                >
                  <el-option
                    v-for="item in userOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="提单号/柜号">
                <base-muti-input
                  ref="baseMutiInput"
                  v-model="filterList.numbers"
                  style="width: 320px"
                ></base-muti-input>
              </el-form-item>
            </el-form>
          </div>
        </BaseFilter>
      </div>
    </div>
    <div class="data">
      <BaseTable
        v-if="height !== 0"
        :key="active"
        :height="height"
        :tableData="tableData"
        :pageIndex="pager.pageIndex"
        :pageSize="pager.pageSize"
        :loading="loading"
        @handlePageChange="handlePageChange"
        @handleSelectionChange="handleSelectionChange"
      >
        <template slot="blNo" slot-scope="row">
          {{ row.scope.blNo }}
          <img
            style="cursor: pointer;"
            src="@/assets/images/const/viewBill.png"
            title="跟进记录"
            @click="viewlog(row.scope)"
          />
        </template>
        <template slot="opt" slot-scope="row">
          <div class="table-opt">
            <span
              v-if="active === 0 && row.scope.status === 0"
              v-permissson="'entering_0'"
              @click="entering(row.scope)"
            >录入</span
            >
            <span
              v-if="active === 0 && row.scope.status !== 0"
              v-permissson="'detail_0'"
              @click="detail(row.scope)"
            >查看详情</span
            >
            <span
              v-if="active === 1"
              v-permissson="'entering'"
              @click="entering(row.scope)"
            >录入</span
            >
            <span
              v-if="active === 2"
              v-permissson="'detail_b'"
              @click="detail(row.scope)"
            >查看详情</span
            >
            <span
              v-if="active === 3"
              v-permissson="'detail_c'"
              @click="detail(row.scope)"
            >查看详情</span
            >
            <span
              v-if="active === 4"
              v-permissson="'detail_d'"
              @click="detail(row.scope)"
            >查看详情</span
            >
            <span
              v-if="active === 5"
              v-permissson="'detail_e'"
              @click="detail(row.scope)"
            >查看详情</span
            >
            <span
              v-if="active === 4"
              v-permissson="'complete'"
              @click="complete(row.scope)"
            >完成</span
            >
          </div>
        </template>
        <template #custom-total>
          <div class="custom-total2">
            <div style="display: flex;">
              <div class="select">已选 {{ selectionItem.length }} 项</div>
              <div class="total" v-html="totalHtml">
              </div>
            </div>
            <!-- <div class="select">已选 {{ selectionItem.length }} 项</div> -->
            <div class="total">
              <div>
                应收合计：<span>{{ receivableTotalAmount }} USD</span>
              </div>
              <div>
                应付合计：<span>{{ payableTotalAmount }} USD</span>
              </div>
              <div>
                利润合计：<span>{{ profitTotalAmount }} USD</span>
              </div>
            </div>
          </div>
        </template>
      </BaseTable>
    </div>
    <ReceivablesDetailDialog
      :dialogVisible="dialogVisible1"
      :rowData="rowData"
      :status="active"
      @cancel="dialogVisible1 = false"
      @ok="ok"
    ></ReceivablesDetailDialog>
    <follow-up-records-drawer
      :drawer="drawer"
      :rowData="{
        ...rowData,
        id: rowData.mblId,
        status: 4
      }"
      :bill-type="1"
      @close="drawer = false"
    ></follow-up-records-drawer>
  </div>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
const FollowUpRecordsDrawer = () => import('@/components/drawer/FollowUpRecordsDrawer')
const BaseFilter = () => import('@/components/filter/BaseFilter')
const BaseMutiInput = () => import('@/components/base/BaseMutiInput.vue')
import ReceivablesDetailDialog from './components/ReceivablesDetailDialog'
import { getReceivablePages, completeReceivable } from '@/api/fee'
import { getSaleList } from '@/api/system'
import { permissions, derive } from '@/utils/common'
import { mapGetters } from 'vuex'
import { myMixins } from '@/mixins'
export default {
  name: 'Receivables',
  components: {
    BaseTable,
    BaseFilter,
    BaseMutiInput,
    ReceivablesDetailDialog,
    FollowUpRecordsDrawer
  },
  mixins: [myMixins],
  data() {
    return {
      pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
        },
      active: 0,
      tableData: {
        fileds: [
          {
            filed: 'companyName',
            label: '客户',
            width: '210'
          },
          {
            filed: 'blNo',
            label: '主提单号',
            width: '180',
            isCopy: true,
            isCustom: true,
            align: 'left'
          },
          {
            filed: 'containerNumber',
            label: '柜号',
            width: '160',
            isCopy: true
          },
          {
            filed: 'eta',
            label: 'ETA',
            width: '160',
            formatter: (row) =>
              row.eta ? row.eta.slice(0, row.eta.length - 9) : ''
          },
          {
            filed: 'typeName',
            label: '提单类型',
            width: '100'
          },
          {
            filed: 'containertype',
            label: '柜型',
            width: '100'
          },
          {
            filed: 'pickUpTypeName',
            label: '派送类型',
            width: '100'
          },
          {
            filed: 'destinationHarborName',
            label: '目的港',
            width: '200'
          },
          {
            filed: 'qty',
            label: '件数',
            width: '130'
          },
          {
            filed: 'weightCbm',
            label: '重量',
            width: '160',
            formatter: (row) =>
              row.weightKgs ? row.weightKgs + ' KG' : '0 KG'
          },
          {
            filed: 'weightCbm',
            label: '材积',
            width: '160',
            formatter: (row) =>
              row.weightCbm ? row.weightCbm + ' CBM' : '0 CBM'
          },
          {
            filed: 'destinationHarborName',
            label: '目的港',
            width: '160'
          },
          {
            filed: 'consigneeName',
            label: '收货人',
            width: '160'
          },
          {
            filed: 'saleName',
            label: '销售员',
            width: '180'
          },
          {
            filed: 'receivableAmount',
            label: '应收合计',
            width: '120',
            formatter: (row) =>
              row.receivableAmount ? row.receivableAmount + ' USD' : '0 USD'
          },
          {
            filed: 'payableAmount',
            label: '应付合计',
            width: '120',
            formatter: (row) =>
              row.payableAmount ? row.payableAmount + ' USD' : '0 USD'
          }, {
          filed: 'opt',
          label: '操作',
          width: '150',
          fixed: 'right',
          isCustom: true
        }
        ],
        columns: [],
        total: 0,
        mutiCheck: true,
        mutiFixed: 'left'
      },
      loading: false,
      pager: {
        pageIndex: 1,
        pageSize: 20
      },
      height: 0,
      filterList: {
        numbers: '',
        companyId: '',
        saleIds: '',
        invoiceDate: ''
      },
      isActive: false,
      dialogVisible1: false,
      drawer: false,
      rowData: {},
      selectionItem: [],
      userOptions: [],
      receivableTotalAmount: 0,
      payableTotalAmount: 0,
      profitTotalAmount: 0,
      tabNumer: [],
      tabTotal: 0,
      tabList: [
        { name: '全部', permissson: 'QB', type: 10000 },
        { name: '未录入', permissson: 'WLR', type: 0 },
        { name: '应付已录', permissson: 'YFYL', type: 1 },
        { name: '待审核', permissson: 'DSH', type: 2 },
        { name: '已审核', permissson: 'YSH', type: 3 },
        { name: '已完成', permissson: 'YWC', type: 4 }
      ], // tab按钮权限
      deriveListA: ['derive_0', 'derive_a', 'derive_b', 'derive_c', 'derive_d', 'derive_e'] // 导出按钮的权限验证
    }
  },
  computed: {
    ...mapGetters(['companyDrapdown']),
    companyOptions() {
      return this.companyDrapdown
    },
    totalHtml() {
      if (this.selectionItem.length === 0) {
        return ''
      }
      const arr1 = [] // 应收合计
      const arr2 = [] // 应付合计
      this.selectionItem.forEach(a => {
        const item = arr1.find(n => n.currency === a.currency)
        if (!item) {
          arr1.push({
            currency: a.currency,
            num: a.receivableAmount
          })
        } else {
          item.num += +a.receivableAmount
        }
        const item2 = arr2.find(n => n.currency === a.currency)
        if (!item2) {
          arr2.push({
            currency: a.currency,
            num: a.payableAmount
          })
        } else {
          item2.num += +a.payableAmount
        }
      })
      const num1Arr = []
      const num2Arr = []
      let str1 = '&nbsp;&nbsp;应收合计：'
      arr1.forEach((a, index) => {
        num1Arr.push(a.num)
        str1 += `<span style="color: #fe453d;font-weight: bold;${index > 0 ? 'margin-left: 10px' : ''}"> ${a.num}  ${a.currency}</span>`
      })
      let str2 = '&nbsp;&nbsp;应付合计：'
      arr2.forEach((a, index) => {
        num2Arr.push(a.num)
        str2 += `<span style="
        color: #fe453d;font-weight: bold;${index > 0 ? 'margin-left: 10px' : ''}"> ${a.num}  ${a.currency}</span>`
      })
      let str3 = ''
      if (num1Arr.length > 0 && num2Arr.length > 0) {
        str3 = `&nbsp;&nbsp;利润合计：<span style="color: #fe453d;font-weight: bold;"> `
        num1Arr.forEach((a, index) => {
          str3 += `&nbsp;&nbsp;${a - num2Arr[index] + arr1[index].currency}`
        })
        str3 += '</span>'
      }
      // const str3 = `&nbsp;&nbsp;利润合计：<span style="color: #fe453d;font-weight: bold;"> ${num1 + num2}</span>`
      return `${str1} ${str2} ${str3}`
    }
  },
  created() {
    if (!this.companyDrapdown.length) {
      this.$store.dispatch('getCompanyDrapdown')
    }
  },
  mounted() {
    this.height = document.querySelector('.data').offsetHeight - 93
    if (!this.$store.state.connect.connect.tabCode) {
      const menuList = this.$store.getters.initRouter
      const menu = permissions(this.$route.meta.code, menuList)
      const codeList = ['WLR', 'YFYL', 'DSH', 'YSH', 'YWC']
      const childrenCode = menu.children.map(a => a.code)
      this.active = codeList.findIndex((a) => childrenCode.includes(a))
      this.getList(this.pager)
      this.getUserList()
    }
  },
  methods: {
    changeType(type) {
      this.receivableTotalAmount = 0
      this.active = type
      const param = {
        pageIndex: 1,
        pageSize: this.pager.pageSize
      }
      this.pager = param
      this.selectionItem = []
      this.getList(param)
    },
    getUserList() {
      getSaleList().then((res) => {
        if (res.success) {
          const arr = []
          res.data.map((item) => {
            arr.push({
              value: item.id,
              label: item.nameCH
            })
          })
          this.userOptions = arr
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    async getList(params) {
      try {
        this.loading = true
        params = {
          ...params,
          queryParam: {
            ...this.formatterFilter()
          }
        }
        const res = await getReceivablePages(params)
        this.loading = false
        if (res.success) {
          res.data.list && (this.tableData.columns = res.data.list)
          this.tableData.total = res.data.total
          res.data.receivableTotalAmount &&
            (this.receivableTotalAmount = res.data.receivableTotalAmount)
          this.payableTotalAmount = res.data.payableTotalAmount
          this.profitTotalAmount = res.data.profitTotalAmount
          this.tabNumer = res.data.tabNumer
          this.tabTotal = res.data.tabTotal
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    formatterFilter() {
      const statusArr = [undefined, 0, 1, 2, 3, 4]
      return {
        status: statusArr.find((a, i) => i === this.active),
        numbers:
          this.filterList.numbers.length > 0
            ? this.filterList.numbers
            : undefined,
        companyId:
          this.filterList.companyId !== ''
            ? this.filterList.companyId
            : undefined,
        saleIds:
          this.filterList.saleIds.length > 0
            ? this.filterList.saleIds
            : undefined,
        invoiceBeginDate:
          this.filterList.date !== ''
            ? this.filterList.invoiceDate[0]
            : undefined,
        invoiceEndDate:
          this.filterList.date !== ''
            ? this.filterList.invoiceDate[1]
            : undefined
      }
    },
    initList() {
      const param = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pager = param
      this.getList(param)
    },
    sendFilter() {
      if (
        this.filterList.numbers.length !== 0 ||
        this.filterList.invoiceDate !== '' ||
        this.filterList.saleIds !== '' ||
        this.filterList.companyId !== ''
      ) {
        this.isActive = true
      } else {
        this.isActive = false
      }
      const params = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pager = params
      this.getList(params)
    },
    reset() {
      this.filterList = {
        numbers: '',
        companyId: '',
        saleIds: '',
        invoiceDate: ''
      }
      this.$refs.baseMutiInput.reset()
      this.isActive = false
    },
    handlePageChange(page) {
      this.pager = page
      this.getList(page)
    },
    handleSelectionChange(items) {
      this.selectionItem = items
    },
    // 录入
    entering(row) {
      this.rowData = row
      this.dialogVisible1 = true
    },
    // 详情
    detail(row) {
      this.rowData = row
      this.dialogVisible1 = true
    },
    // 完成
    async complete(row) {
      try {
        const result = await completeReceivable({ id: row.id })
        if (result.success) {
          this.getList(this.pager)
          this.$message.success(result.errorMessage)
        } else {
          this.$message.error(result.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    ok() {
      this.getList(this.pager)
    },
    // 导出
    derive() {
      const params = {
        ...this.formatterFilter()
      }
      derive('Receivable/exportReceivable', 'post', params)
    },
    // 跟进记录
    viewlog(row) {
      this.drawer = true
      this.rowData = row
    }
  }
}
</script>

<style lang="less" scoped>
.view-contanier {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  .header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tabItem {
      padding: 15px 20px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      cursor: pointer;
      box-sizing: border-box;
    }
    .active {
      color: #2395f3;
    }
  }
  .opt {
    display: flex;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    & div {
      height: 30px;
      box-sizing: border-box;
      margin-right: 5px;
      border-radius: 4px;
      cursor: pointer;
    }
    .derive_a {
      width: 80px;
      background: #2395f3;
    }
    .derive_b {
      width: 120px;
      border: 1px solid #2395f3;
      color: #2395f3;
    }
  }
  .data {
    width: 100%;
    height: calc(100% - 50px);
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
  }
  .custom-total1 {
    position: absolute;
    bottom: 12px;
    left: 0;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
  }
  .custom-total2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    background: #f7f7f7;
    border: 1px solid #eeeeee;
    border-top: 0;
    padding-left: 17px;
    padding-right: 40px;
    box-sizing: border-box;
    .select {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
    .total {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      color: #333333;
      span {
        color: #fe453d;
        font-weight: bold;
      }
      div + div {
        margin-left: 20px;
      }
    }
  }
  .table-opt {
    display: flex;
    justify-content: space-around;
    span {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395f3;
      cursor: pointer;
      & + span {
        margin-left: 20px;
      }
    }
  }
}
</style>
